/** @jsx jsx */

import { Link } from 'gatsby'
import { FC } from 'react'
import { Flex, jsx, useColorMode } from 'theme-ui'

import routes from '../constants/routes'
import theme from '../gatsby-plugin-theme-ui'

const navigationItems = [
  { to: routes.privateCompanies, children: `Private companies` },
  { to: routes.institutionalInvestors, children: `Institutional investors` },
  { to: routes.contentCenter, children: `Content center` },
]

interface NavProps {
  location?: { pathname: string }
}

const Nav: FC<NavProps> = ({ location }) => {
  const [colorMode, setColorMode] = useColorMode()

  const isHome = location?.pathname === routes.home

  function logotype() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 859.1 209.8"
        sx={{ width: 84, height: 22, display: `block`, color: `cartaXLogoPrimary` }}
      >
        <title>CartaX</title>
        <path
          d="M326.59,365H305a1.23,1.23,0,0,0-1.19,1,32.48,32.48,0,0,1-35.16,27.91,35.21,35.21,0,0,1-29.67-12.23,63,63,0,0,1-9.47-38.52V317.51q0-26.89,9.47-39a36.17,36.17,0,0,1,30.27-12.08,39.72,39.72,0,0,1,24.61,6.88,22.64,22.64,0,0,1,9.93,16.95,1.22,1.22,0,0,0,1.21,1.05h21.2a1.24,1.24,0,0,0,1.25-1.22v0a39.73,39.73,0,0,0-11.11-27.28Q301.32,246,268,246A59,59,0,0,0,221.21,264q-15.9,18.06-15.89,53.81v30.26q0,33,15.89,49.84A61.36,61.36,0,0,0,268,414.76q37.31,0,51.36-21.09a50.46,50.46,0,0,0,6.58-13,56,56,0,0,0,1.84-14.35A1.23,1.23,0,0,0,326.59,365Z"
          transform="translate(-205.32 -205.12)"
          style={{ fill: `currentColor` }}
        />
        <path
          d="M471.63,283.77A54.13,54.13,0,0,0,461.11,264a44.58,44.58,0,0,0-12.17-9.82A66.74,66.74,0,0,0,414.38,246q-33.33,0-48.37,16.8a39,39,0,0,0-10.37,27.1,1.24,1.24,0,0,0,1.23,1.25H378.1a1.19,1.19,0,0,0,1.18-1.1,21.54,21.54,0,0,1,9.27-16.72,39.66,39.66,0,0,1,24.58-6.91,36.15,36.15,0,0,1,30.23,12.09l.08.08a24.91,24.91,0,0,1,5.26,13.19c.16,1.65.24,3.38.24,5.26v18c-6.29-.79-12.65-1.42-18.93-1.81s-11.54-.63-15.63-.63c-22.22,0-38.48,4.24-48.92,12.57s-15.55,21.59-15.55,39.73q0,25.68,14.84,37.15c8.64,6.75,26.94,10.44,45.39,11.23,2.2.08,4.32.08,6.52,0,2.67-.08,5.34-.24,8-.55a192.66,192.66,0,0,0,24.27-3.7h0c1.06-.23,2.11-.49,3.16-.73l1.52-.35q8.44-2.06,16.68-4.89a4.12,4.12,0,0,0,2.82-3.69v-103c0-2.35-.15-4.55-.31-6.67A25.89,25.89,0,0,0,471.63,283.77ZM447.92,386.33a92.68,92.68,0,0,1-59.53,2,18.83,18.83,0,0,1-11.7-11.07,36,36,0,0,1-2-12.72,29.61,29.61,0,0,1,9-24.42c6-5,16.88-7.39,32.51-7.39,3.45,0,23.79,1.73,31.41,2.6a1.56,1.56,0,0,1,1.34,1.57v47.9A1.62,1.62,0,0,1,447.92,386.33Z"
          transform="translate(-205.32 -205.12)"
          style={{ fill: `currentColor` }}
        />
        <path
          d="M555.27,250.16A42.28,42.28,0,0,0,528.41,270a7.38,7.38,0,0,0-.71,1.33V252a1.31,1.31,0,0,0-1.32-1.33h-20.9a1.34,1.34,0,0,0-1.34,1.32h0V409.26a1.34,1.34,0,0,0,1.32,1.34h21.61a1.46,1.46,0,0,0,1.34-.87c0-.15.08-.31.08-.47l-.16-88.11c0-1.33.08-2.59.08-3.85a68.54,68.54,0,0,1,6.05-26.23A35.66,35.66,0,0,1,565,270a121.31,121.31,0,0,1,14.13-.79l5.81.08a1.19,1.19,0,0,0,1.1-1.18v-21C586.05,243.09,558.09,249.3,555.27,250.16Z"
          transform="translate(-205.32 -205.12)"
          style={{ fill: `currentColor` }}
        />
        <path
          d="M690.18,246H654.77V206.54a1.25,1.25,0,0,0-1.26-1.22l-21.67,6.59h0a1.25,1.25,0,0,0-1.22,1.26V246H606.94a1.24,1.24,0,0,0-1.26,1.18V266.5a1.25,1.25,0,0,0,1.26,1.23h23.64v102a43.18,43.18,0,0,0,9.5,30.86,40.43,40.43,0,0,0,30.31,9.82h19.83a1.24,1.24,0,0,0,1.22-1.26V390.26a1.23,1.23,0,0,0-1.26-1.18H672.51a15.93,15.93,0,0,1-13.58-5.42,29.06,29.06,0,0,1-4.16-17.82V267.73h35.45a1.24,1.24,0,0,0,1.22-1.26V247.15A1.24,1.24,0,0,0,690.18,246Z"
          transform="translate(-205.32 -205.12)"
          style={{ fill: `currentColor` }}
        />
        <path
          d="M833.88,289.74a25.89,25.89,0,0,0-1.18-6A54.13,54.13,0,0,0,822.18,264,44.58,44.58,0,0,0,810,254.16,66.74,66.74,0,0,0,775.45,246q-33.33,0-48.37,16.8a39,39,0,0,0-10.37,27.1,1.24,1.24,0,0,0,1.23,1.25h21.23a1.19,1.19,0,0,0,1.18-1.1,21.54,21.54,0,0,1,9.27-16.72,39.66,39.66,0,0,1,24.58-6.91,36.15,36.15,0,0,1,30.23,12.09l.08.08a24.91,24.91,0,0,1,5.26,13.19c.16,1.65.24,3.38.24,5.26v18c-6.29-.79-12.65-1.42-18.93-1.81s-11.54-.63-15.63-.63c-22.22,0-38.48,4.24-48.92,12.57S711,346.75,711,364.89q0,25.68,14.84,37.15c8.64,6.75,26.94,10.44,45.39,11.23,2.2.08,4.32.08,6.52,0,2.67-.08,5.34-.24,8-.55A192.66,192.66,0,0,0,810,409h0c1.06-.23,2.11-.49,3.17-.73l1.51-.35q8.44-2.06,16.68-4.89a4.12,4.12,0,0,0,2.82-3.69v-103C834.19,294.06,834,291.86,833.88,289.74ZM809,386.33a92.69,92.69,0,0,1-59.53,2,18.81,18.81,0,0,1-11.7-11.07,36,36,0,0,1-2-12.72,29.61,29.61,0,0,1,9-24.42c6-5,16.88-7.39,32.51-7.39,3.45,0,23.79,1.73,31.41,2.6a1.57,1.57,0,0,1,1.34,1.57v47.9A1.62,1.62,0,0,1,809,386.33Z"
          transform="translate(-205.32 -205.12)"
          style={{ fill: `currentColor` }}
        />
        <polygon
          points="791.33 99.51 766.06 139.13 808.28 205.32 859.1 205.32 791.33 99.51"
          style={{ fill: `#02a1fe` }}
        />
        <polygon points="854.8 0 804.54 0 766.06 60.07 791.33 99.51 854.8 0" style={{ fill: `#02a1fe` }} />
        <polygon
          points="766.06 60.07 727.59 0 677.33 0 740.8 99.51 673.02 205.32 723.85 205.32 766.06 139.13 791.33 99.51 766.06 60.07"
          style={{ fill: `currentColor` }}
        />
      </svg>
    )
  }

  return (
    <header sx={{ backgroundColor: isHome ? `transparent_black` : `transparent` }}>
      <Flex
        as="nav"
        sx={{ maxWidth: `var(--maxContentWidth)`, height: `5rem`, mx: `auto`, px: 4, alignItems: `center` }}
      >
        <Link to={routes.home} sx={{ mb: 2, pl: `1px` }}>
          {logotype()}
        </Link>

        <Flex
          as="ul"
          sx={{
            mt: 0,
            pl: 0,
            flex: 1,
            justifyContent: `flex-end`,
            listStyleType: `none`,

            [`@media print`]: {
              display: `none`,
            },
          }}
        >
          {navigationItems.map((item, index) => (
            <li
              key={item.to}
              sx={{ display: index === navigationItems.length - 1 ? [`none`, `none`, `block`] : [`none`, `block`] }}
            >
              <Link
                key={item.children}
                sx={{
                  px: `1rem`,
                  display: `block`,
                  textDecoration: `none`,
                  color: `gray6_slate3`,

                  [`:hover, :focus`]: {
                    textDecoration: `underline`,
                  },
                }}
                {...item}
              />
            </li>
          ))}

          <li sx={{ display: [`block`, `none`] }}>
            <a
              href="#nav"
              sx={{
                px: `1rem`,
                display: `block`,
                textDecoration: `none`,
                color: `gray6_slate3`,

                [`:hover, :focus`]: {
                  textDecoration: `underline`,
                },
              }}
            >
              Menu
            </a>
          </li>
        </Flex>

        <button
          aria-label="Toggle color mode"
          onClick={() => setColorMode(colorMode === `dark` ? `light` : `dark`)}
          sx={{
            my: 0,
            mr: 0,
            ml: 2,
            border: `1px solid`,
            p: `2px`,
            borderRadius: 999,
            opacity: 0.75,
            color: `gray6_slate3`,
            bg: `transparent`,
            appearance: `none`,
            cursor: `pointer`,

            [`:hover, :focus`]: {
              opacity: 1,
            },

            [`:focus`]: {
              outline: `none`,
              boxShadow: `0 0 0 2px ${theme.colors.blue4}, 0 0 0 4px ${theme.colors.white}`,
            },

            [`@media print`]: {
              display: `none`,
            },
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" sx={{ size: [16], display: `block` }}>
            <path
              fill="currentcolor"
              d="M14.53 10.53a7 7 0 01-9.058-9.058A7.003 7.003 0 008 15a7.002 7.002 0 006.53-4.47z"
            ></path>
          </svg>
        </button>
      </Flex>
    </header>
  )
}

export default Nav
