/** @jsx jsx */

import { Global } from '@emotion/react'
import { FC, useEffect } from 'react'
import { Box, jsx, useColorMode } from 'theme-ui'

import gridIntro from '../assets/backgrounds/grid-intro.svg'
import Footer from './footer'
import Navigation from './navigation'

interface LayoutProps {
  containerStyles?: {}
  includeVisual?: boolean
  location?: {
    pathname: string
  }
}

const Layout: FC<LayoutProps> = ({ containerStyles, includeVisual = true, location, children }) => {
  const [, setColorMode] = useColorMode()

  useEffect(() => {
    const switchMode = ({ matches }) => {
      const isDarkMode = matches

      isDarkMode ? setColorMode(`dark`) : setColorMode(`light`)
    }

    const darkModeMediaQuery =
      typeof window !== `undefined` && window.matchMedia && window.matchMedia(`(prefers-color-scheme: dark)`)

    if (!!darkModeMediaQuery.addEventListener) {
      darkModeMediaQuery.addEventListener(`change`, switchMode)
    } else {
      darkModeMediaQuery.addListener(switchMode)
    }

    return () => {
      if (!!darkModeMediaQuery.removeEventListener) {
        return darkModeMediaQuery.removeEventListener(`change`, switchMode)
      } else {
        return darkModeMediaQuery.removeListener(switchMode)
      }
    }
  }, [setColorMode])

  return (
    <Box
      sx={{
        ...containerStyles,
        ...(includeVisual
          ? {
              backgroundImage: [`none`, null, null, `url(${gridIntro})`],
              backgroundRepeat: `no-repeat`,
              backgroundPosition: `calc(100% + 570px) -78px`,
              backgroundSize: `calc(2727.39px / 1.65) calc(1251.23px / 1.65)`,
            }
          : {}),
      }}
    >
      <Global
        styles={(theme) => `
          @import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

          @keyframes fade-in {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes fade-out {
            from { opacity: 1; }
            to { opacity: 0; }
          }

          @keyframes slide-up-pre-title {
            from { transform: translateY(10%); }
            to { transform: translateY(-20%); }
          }
 
          @keyframes slide-up-title {
            from { transform: translateY(10%); }
            to { transform: translateY(0); }
          }

          @media print {
            body {
              color: ${theme.colors.black} !important;
              background-color: ${theme.colors.black} !important;
            }

            main a::after,
            footer a::after {
              content: " (" attr(href) ")";
            }
          }

          :root {
            --maxContentWidth: 1000px;
            --gradient: linear-gradient(-228deg, #41d4c7, #2f71e3);
            /* scroll-behavior: smooth; */
          }

          :focus {
            outline-color: ${theme.colors.blue4};
          }

          *,
          ::before,
          ::after {
            box-sizing: border-box;
          }

          body {
            margin: 0;
            font: 1em/1.5 ${theme.fonts.body};
            color: ${theme.colors.text};
            background-color: ${theme.colors.background};
            overflow-y: scroll;
          }

          h1,
          h2,
          h3 {
            font-weight: 600;
            font-family: ${theme.fonts.heading};
          }

          h1 {
            letter-spacing: -0.025em;
            line-height: 1.125;
          }

          h2,
          h3 {
            letter-spacing: -0.0125em;
            line-height: 1.35;
          }

          a {
            color: ${theme.colors.primary};
          }

          img[height="1"][width="1"] {
            margin-top: -1px;
            display: block;
          }
        `}
      />

      <Navigation {...{ location }} />
      <main>{children}</main>
      <Footer />
    </Box>
  )
}

export default Layout
