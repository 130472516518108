/** @jsx jsx */

import { Link } from 'gatsby'
import { FC } from 'react'
import { jsx } from 'theme-ui'

import { RouteName } from '../constants/routes'

interface UniversalLinkProps {
  to?: RouteName | string
  href?: RouteName | string
}

const UniversalLink: FC<UniversalLinkProps> = ({ to, href, ...rest }) => {
  return to ? <Link {...{ to, ...rest }} /> : <a {...{ href, ...rest }} target="_blank" rel="noopener noreferrer" />
}

export default UniversalLink
