/** @jsx jsx */

import { FC } from 'react'
import { Box, Flex, Grid, jsx } from 'theme-ui'

import routes from '../constants/routes'
import { copyright, links } from '../content/footer'
import UniversalLink from './universal-link'

const Footer: FC = () => {
  const linkStyles = {
    pt: [2, 1],
    pb: [2, 1],
    display: `block`,
    textDecoration: `none`,
    color: `inherit`,

    [`:hover, :focus`]: {
      textDecoration: `underline`,
    },
  }

  return (
    <Box id="nav" as="footer" sx={{ bg: `black`, color: `gray1` }}>
      <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, py: 5, px: 4 }}>
        <Grid
          as="ul"
          sx={{
            mt: 0,
            mb: 4,
            pl: 0,
            gap: 3,
            gridTemplateColumns: `repeat(auto-fill, minmax(260px, 1fr))`,
            listStyleType: `none`,

            [`@media print`]: {
              display: `none`,
            },
          }}
        >
          {links.map(({ children, to, href, relatedLinks }) => {
            return (
              <li key={`${children}`} sx={{ mb: 4 }}>
                {to || href ? (
                  <UniversalLink
                    sx={{
                      ...linkStyles,
                      mb: 2,
                      pt: 0,
                      fontWeight: 600,
                      fontSize: 1,
                      textTransform: `uppercase`,
                      letterSpacing: `0.1em`,
                      color: `gray3`,
                    }}
                    {...{ to, href, children }}
                  />
                ) : (
                  <div
                    sx={{
                      mb: 2,
                      pt: 0,
                      pb: [2, 1],
                      fontWeight: 600,
                      fontSize: 1,
                      textTransform: `uppercase`,
                      letterSpacing: `0.1em`,
                      color: `gray3`,
                    }}
                    {...{ children }}
                  />
                )}
                {relatedLinks && (
                  <ul sx={{ my: 0, pl: 0, listStyleType: `none` }}>
                    {relatedLinks.map((item) => (
                      <li key={`${item.children}`}>
                        <UniversalLink sx={linkStyles} {...item} />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )
          })}
        </Grid>

        <Flex
          sx={{
            borderTop: `1px solid`,
            borderTopColor: `gray3`,
            py: 4,
            justifyContent: `space-between`,
            alignItems: `center`,
            color: `inherit`,
          }}
        >
          <div sx={{ fontSize: [1, 2] }}>
            © Carta Capital Markets, LLC | Member{` `}
            <UniversalLink href={routes.finra} sx={{ color: `inherit` }}>
              FINRA
            </UniversalLink>
            /
            <UniversalLink href={routes.sipc} sx={{ color: `inherit` }}>
              SIPC
            </UniversalLink>
          </div>

          <Flex sx={{ mr: -2, flex: `128px 0 0`, [`@media print`]: { display: `none` } }}>
            <Flex
              as={UniversalLink}
              href={routes.facebook}
              sx={{
                size: [40],
                justifyContent: `center`,
                alignItems: `center`,
                opacity: 0.8,
                textDecoration: `none`,
                color: `inherit`,

                [`:hover, :focus`]: {
                  opacity: 1,
                  textDecoration: `underline`,
                },
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" sx={{ size: [26] }}>
                <title>Follow Carta, Inc. on Facebook</title>
                <path
                  sx={{ fill: `rgba(255, 255, 255, 0.2)` /* `text` */ }}
                  d="M1024,512C1024,229.23,794.77,0,512,0S0,229.23,0,512c0,255.55,187.23,467.37,432,505.78V660H302V512H432V399.2C432,270.88,508.44,200,625.39,200c56,0,114.61,10,114.61,10V336H675.44c-63.6,0-83.44,39.47-83.44,80v96H734L711.3,660H592v357.78C836.77,979.37,1024,767.55,1024,512Z"
                />
                <path
                  sx={{ fill: `gray1` /* `background` */ }}
                  d="M711.3,660,734,512H592V416c0-40.49,19.84-80,83.44-80H740V210s-58.59-10-114.61-10C508.44,200,432,270.88,432,399.2V512H302V660H432v357.78a517.58,517.58,0,0,0,160,0V660Z"
                />
              </svg>
            </Flex>
            <Flex
              as={UniversalLink}
              href={routes.linkedIn}
              sx={{
                size: [40],
                ml: 1,
                justifyContent: `center`,
                alignItems: `center`,
                opacity: 0.8,
                textDecoration: `none`,
                color: `inherit`,

                [`:hover, :focus`]: {
                  opacity: 1,
                  textDecoration: `underline`,
                },
              }}
            >
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" sx={{ size: [24] }}>
                <title>Connect with Carta, Inc. on LinkedIn</title>
                <path
                  d="M512,64c0,-35.323 -28.677,-64 -64,-64l-384,0c-35.323,0 -64,28.677 -64,64l0,384c0,35.323 28.677,64 64,64l384,0c35.323,0 64,-28.677 64,-64l0,-384Z"
                  sx={{ fill: `rgba(255, 255, 255, 0.2)` /* `text` */ }}
                />
                <rect width="85.76" height="257.962" x="61.053" y="178.667" sx={{ fill: `gray1` /* `background` */ }} />
                <path
                  d="M104.512,54.28c-29.341,0 -48.512,19.29 -48.512,44.573c0,24.752 18.588,44.574 47.377,44.574l0.554,0c29.903,0 48.516,-19.822 48.516,-44.574c-0.555,-25.283 -18.611,-44.573 -47.935,-44.573Z"
                  sx={{ fill: `gray1` /* `background` */ }}
                />
                <path
                  d="M357.278,172.601c-45.49,0 -65.866,25.017 -77.276,42.589l0,-36.523l-85.738,0c1.137,24.197 0,257.961 0,257.961l85.737,0l0,-144.064c0,-7.711 0.554,-15.42 2.827,-20.931c6.188,-15.4 20.305,-31.352 43.993,-31.352c31.012,0 43.436,23.664 43.436,58.327l0,138.02l85.741,0l0,-147.93c0,-79.237 -42.305,-116.097 -98.72,-116.097Z"
                  sx={{ fill: `gray1` /* `background` */ }}
                />
              </svg>
            </Flex>
            <Flex
              as={UniversalLink}
              href={routes.twitter}
              sx={{
                size: [40],
                ml: 1,
                justifyContent: `center`,
                alignItems: `center`,
                opacity: 0.8,
                textDecoration: `none`,
                color: `inherit`,

                [`:hover, :focus`]: {
                  opacity: 1,
                  textDecoration: `underline`,
                },
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" sx={{ size: [24] }}>
                <title>Follow Carta, Inc. on Twitter</title>
                <path
                  sx={{ fill: `rgba(255, 255, 255, 0.2)` /* `text` */ }}
                  d="M350,400H50c-27.6,0-50-22.4-50-50V50C0,22.4,22.4,0,50,0h300c27.6,0,50,22.4,50,50v300 C400,377.6,377.6,400,350,400z"
                />
                <path
                  sx={{ fill: `gray1` /* `background` */ }}
                  d="M153.6,301.6c94.3,0,145.9-78.2,145.9-145.9c0-2.2,0-4.4-0.1-6.6c10-7.2,18.7-16.3,25.6-26.6 c-9.2,4.1-19.1,6.8-29.5,8.1c10.6-6.3,18.7-16.4,22.6-28.4c-9.9,5.9-20.9,10.1-32.6,12.4c-9.4-10-22.7-16.2-37.4-16.2 c-28.3,0-51.3,23-51.3,51.3c0,4,0.5,7.9,1.3,11.7c-42.6-2.1-80.4-22.6-105.7-53.6c-4.4,7.6-6.9,16.4-6.9,25.8 c0,17.8,9.1,33.5,22.8,42.7c-8.4-0.3-16.3-2.6-23.2-6.4c0,0.2,0,0.4,0,0.7c0,24.8,17.7,45.6,41.1,50.3c-4.3,1.2-8.8,1.8-13.5,1.8 c-3.3,0-6.5-0.3-9.6-0.9c6.5,20.4,25.5,35.2,47.9,35.6c-17.6,13.8-39.7,22-63.7,22c-4.1,0-8.2-0.2-12.2-0.7 C97.7,293.1,124.7,301.6,153.6,301.6"
                />
              </svg>
            </Flex>
          </Flex>
        </Flex>

        <div sx={{ my: 0, fontSize: 1, color: `gray4` }}>{copyright}</div>
      </div>
    </Box>
  )
}

export default Footer
