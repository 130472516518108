/** @jsx jsx */

import { Fragment, ReactElement } from 'react'
import { jsx } from 'theme-ui'

import UniversalLink from '../components/universal-link'
import routes, { RouteName } from '../constants/routes'

export interface Link {
  to?: RouteName | string
  href?: RouteName | string
  children: string | ReactElement
  relatedLinks?: {
    to?: RouteName | string
    href?: RouteName | string
    children: string | ReactElement
  }[]
}

export const links: Link[] = [
  {
    to: routes.home,
    children: `Home`,
    relatedLinks: [
      { to: routes.requestInformation, children: `Request information` },
      { to: routes.contentCenter, children: `Content center` },
      { href: routes.carta, children: `Visit carta.com` },
    ],
  },
  {
    to: routes.privateCompanies,
    children: `Private companies`,
    relatedLinks: [
      { to: routes.privateCompaniesCartaCross, children: `Carta Cross` },
      {
        to: `${routes.privateCompanies}#tender-offers-and-company-repurchases`,
        children: `Tender offers and company repurchases`,
      },
    ],
  },
  {
    to: routes.institutionalInvestors,
    children: `Institutional investors`,
    relatedLinks: [
      {
        to: `${routes.institutionalInvestors}#expand-investment-opportunities`,
        children: `Expand investment opportunities`,
      },
      { to: `${routes.institutionalInvestors}#data-driven-investing`, children: `Data-driven investing` },
      { to: `${routes.institutionalInvestors}#portfolio-management`, children: `Portfolio management` },
    ],
  },
  {
    children: `Partners`,
    relatedLinks: [
      { to: routes.partnersLegal, children: `Legal advisory team` },
      { to: routes.partnersAcademic, children: `Academic advisors` },
    ],
  },
  {
    children: `Resources`,
    relatedLinks: [
      { to: routes.brand, children: `Brand guidelines` },
      { to: routes.contactUs, children: `Contact us` },
      { to: routes.documents, children: `Documents library` },
    ],
  },
  {
    children: `Legal`,
    relatedLinks: [
      { to: routes.terms, children: `Terms and conditions` },
      { to: routes.regulatoryDisclosures, children: `Regulatory disclosures` },
    ],
  },
]

export const copyright = (
  <Fragment>
    <p sx={{ mt: 0 }}>
      Check out our profile on{` `}
      <UniversalLink href={routes.finraBrokerCheck} sx={{ color: `inherit` }}>
        FINRA BrokerCheck
      </UniversalLink>
      .
    </p>
    <p sx={{ mt: 0 }}>Connect with Carta, Inc. on Facebook, LinkedIn, and Twitter.</p>
    <p sx={{ mb: 0, textAlign: `justify` }}>
      © Carta Capital Markets, LLC (“CCMX”) | All Rights Reserved | CCMX member{` `}
      <UniversalLink href={routes.finraMember} sx={{ color: `inherit` }}>
        FINRA
      </UniversalLink>
      /SIPC | CCMX undertakes no obligation to update content herein | No business, investment, tax or legal advice is
      provided by CCMX | Potential investors are advised to conduct their own due diligence and consult with their tax,
      legal, and financial advisors with respect to any investment | All securities involve risk and may result in
      partial or total loss | Investments in private securities are speculative, illiquid, and involve a high degree of
      risk, including the possible loss of your entire investment | Access to third-party content from this site,
      including content of CCMX’s parent company, Carta, Inc., may be available through framed areas or hyperlinks.
      Access to such content is for informational purposes only and does not constitute an explicit or implicit
      endorsement by CCMX of such content as to accuracy, timeliness, completeness or usefulness | There is no guarantee
      that a private company will conduct an initial public offering or provide an alternative exit strategy for your
      invested capital | Images are illustrative and may differ from application experience
    </p>
  </Fragment>
)
