/** @jsx jsx */

import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { jsx, useColorMode } from 'theme-ui'

import routes from '../constants/routes'

const base = {
  title: `CartaX`,
  product: `CartaX`,
  route: ``,
  description: `The first vertically integrated market ecosystem for private equity`,
  image: `${routes.origin}/cartax-card.png`,
  author: `@cartainc`,
}

export interface HaloProps {
  title?: string
  divider?: `|` | `/`
  product?: string
  route?: string
  description?: string
  image?: string
  root?: string
  author?: string
}

const Halo: FC<HaloProps> = ({
  title = base.title,
  divider = `|`,
  product = base.product,
  route = base.route,
  description = base.description,
  image = base.image,
  root = `../`,
  author = base.author,
}) => {
  const [colorMode] = useColorMode()

  function themeColorContent() {
    if (colorMode === `dark`) return `#0e1010`
    if (colorMode === `light`) return `#dfeff3`
    if (typeof window !== `undefined` && window.matchMedia && window.matchMedia(`(prefers-color-scheme: dark)`).matches) return `#0e1010`
    if (typeof window !== `undefined` && window.matchMedia && window.matchMedia(`(prefers-color-scheme: light)`).matches) return `#dfeff3`

    return `#dfeff3`
  }

  return (
    <Helmet>
      <title>{`${title}${title === base.title ? `` : ` ${divider} ${product}`}`}</title>
      <link rel="canonical" href={`${routes.origin}${route}`} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="application-name" content={product} />
      <meta name="apple-mobile-web-app-title" content={product} />
      <meta name="theme-color" content={themeColorContent()} />
      <link rel="icon" href={`${root}favicon.svg`} />
      <link rel="mask-icon" href={`${root}favicon.svg`} color="#000000" />
      <link rel="apple-touch-icon" href={`${root}apple-touch-icon.png`} />
      <link rel="manifest" href={`${root}manifest.json`} />

      <meta property="og:url" content={`${routes.origin}${route}`} />
      <meta property="og:site_name" content={product} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <meta property="og:image:alt" content={`${product} logomark`} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content={author} />
      <meta name="twitter:creator" content={author} />
    </Helmet>
  )
}

export default Halo
